<template>
  <iframe id="about" name="about" :src="webUrl" scrolling="auto" frameborder="0" class="iframe" />
</template>

<script>
export default {
  props: {
    webUrl: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>
.iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
    font-size: 14px;
}
</style>
