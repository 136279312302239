<template>
  <WebIframe :web-url="url" />
</template>

<script>
import WebIframe from '@/components/WebIframe'
import { selectByCode } from '@/api/user'
export default {
  components: { WebIframe },
  data() {
    return {
      url: ''
    }
  },
  mounted() {
    this.getUrl()
  },
  methods: {
    async getUrl() {
      const res = await selectByCode({ code: 'ym001' })
      this.url = res.datas.content
    }
  }
}
</script>

<style>

</style>
